<div class="help-modal-container" *ngIf="isOpen">
    <div class="help-modal">
        <i class="material-icons close-button" (click)="closeWindow()">close</i>
        <div class="help-modal-header">
            What if I have some questions?
        </div>
        <div class="help-content-container">
            In case of any support needed during the process, please send an email to <span style="color: blue">{{userEmail}}</span>. Please remember to include the auction number as a reference in the subject.
        </div>
    </div>
</div>
