import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { response } from 'express';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DynamicValue, DynamicValuesDto } from '../model/dynamicvalue';


@Injectable()
export class DynamicValuesApiService {

  private baseURL: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/DynamicValues';
  }
  
  postDynamicValueList(dynamicValues:Array<DynamicValue>, contactId): Observable<any>{
    let dto = new DynamicValuesDto(dynamicValues);
    return this.httpClient.post<any>(this.baseURL+'/Contact/'+contactId, dto);
  }
}