import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent {

  constructor() { }

  @Input()
  showModal = false;

  @Input()
  mainText = '';

  @Output()
  confirm = new EventEmitter();

  @Output()
  close = new EventEmitter();

  onConfirm() {
    this.confirm.emit();
  }

  onCancel() {
    this.close.emit();
  }
}
