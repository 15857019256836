import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.css']
})
export class TabGroupComponent implements OnInit {
  public menus = ['Contacts', 'Documents', 'Monitor', 'Auctions'];

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }

  isSelected(menuName: string) {
    let currentUrl = window.location.href;
    return currentUrl && menuName && currentUrl.toLowerCase().includes(menuName.toLowerCase());
  }

}
