import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'help-modal',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  @Input() userEmail: string;
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  closeWindow() {
    this.isOpen = false;
    this.isOpenChange.emit(false);
  }

}
