<div class="auction-item-offer">
    <div class="item-details" (click)="showEdit()">
        <div class="detail-line item-description">
            <span>{{item.itemDescription}}</span>
            <span class="units">({{item.itemQuantity}} Units)</span>
        </div>
        <div class="detail-line" *ngIf="bestBidPrice">
            <span class="label">Best price</span>
            <span class="value">{{currency}} {{bestBidPrice | number: '1.2-2'}}</span>
        </div>
        <div class="detail-line" *ngIf="bestBidDeliveryDate">
            <span class="label">Best delivery date</span>
            <span class="value">{{bestBidDeliveryDate | date:'MM-dd-YYYY'}}</span>
        </div>
        <div class="detail-line" *ngIf="previousBidPrice">
            <span class="label">Previous price</span>
            <span class="value">{{currency}} {{previousBidPrice | number: '1.2-2'}}</span>
        </div>
        <div class="detail-line" *ngIf="previousBidDeliveryDate">
            <span class="label">Previous delivery date</span>
            <span class="value">{{previousBidDeliveryDate | date:'MM-dd-YYYY'}}</span>
        </div>
        <div class="detail-line">
            <span class="label">Unit Price</span>
            <span class="value">{{item.proposedUnitPrice ? currency : ''}} {{item.proposedUnitPrice | number: '1.2-2'}}</span>
        </div>
        <div class="detail-line" *ngIf="showProposed">
            <span class="label">Proposed Unit Price</span>
            <span class="value">{{currency}} {{item.proposedUnitPrice | number: '1.2-2'}}</span>
        </div>
        <div class="detail-line">
            <span class="label">Delivery Date</span>
            <span class="value">{{item.proposedDeliveryDate | date:'MM-dd-YYYY'}}</span>
        </div>
        <div class="detail-line" *ngIf="showProposed">
            <span class="label">Proposed Delivery Date</span>
            <span class="value">{{item.proposedDeliveryDate | date:'MM-dd-YYYY'}}</span>
        </div>
    </div>
    <div class="item-edit">
        <a class="btn-flat" (click)="showEdit()">
            <fa-icon [icon]="faEdit"></fa-icon>
        </a>
    </div>
    <div class="auction-item-modal" *ngIf="editing" (click)="showEdit()">
        <div class="auction-item-modal-content" (click)="$event.stopPropagation()">
            <div class="modal-title">
                {{item.itemDescription}}
            </div>
            <div style="padding: 10px 20px;">
                <p class="units">({{item.itemQuantity}} Units)</p>
                <div class="detail-line" *ngIf="bestBidPrice">
                    <span class="label">Best Unit Price</span>
                    <span class="value">{{currency}} {{bestBidPrice | number: '1.2-2'}}</span>
                </div>
                <div class="detail-line" *ngIf="bestBidDeliveryDate">
                    <span class="label">Best Delivery Date</span>
                    <span class="value">{{bestBidDeliveryDate | date:'MM-dd-YYYY'}}</span>
                </div>
                <div class="detail-line" *ngIf="previousBidPrice">
                    <span class="label">Previous Unit Price</span>
                    <span class="value">{{currency}} {{previousBidPrice | number: '1.2-2'}}</span>
                </div>
                <div class="detail-line" *ngIf="previousBidDeliveryDate">
                    <span class="label">Previous Delivery Date</span>
                    <span class="value">{{previousBidDeliveryDate | date:'MM-dd-YYYY'}}</span>
                </div>
                <div class="file-field input-field">
                    <div class="btn" style="width: 45px; background-color: #689f38; display: flex; justify-content: center; align-items: center;">
                        <span>{{currency}}</span>
                    </div>
                    <div class="file-path-wrapper">
                        <input class="file-path" type="text" placeholder="Proposed unit price" [(ngModel)]="item.proposedUnitPrice">
                    </div>
                </div>
                <div class="file-field input-field">
                    <div class="btn" style="width: 45px; background-color: #689f38;">
                        <fa-icon [icon]="faCalendar"></fa-icon>
                    </div>
                    <div class="file-path-wrapper">
                        <input
                            class="file-path"
                            type="date"
                            placeholder="Proposed delivery date"
                            [value]="item.proposedDeliveryDate | date:'yyyy-MM-dd'"
                            (input)="parseDate($event.target.value)"
                        >
                    </div>
                </div>
                <div>
                    <a class="waves-effect waves-light btn-small confirm-button" (click)="showEdit()">Confirm</a>
                </div>
            </div>
        </div>
    </div>
</div>