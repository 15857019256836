import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact, ContactDto } from 'src/app/model/contact';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContactsApiService {
  private baseURL: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Contacts';
  }

  getContactsList(companyId: number): Observable<Contact[]> {
    return this.httpClient.get<Contact[]>(this.baseURL+'/Company/' + companyId);//PARAMETRIZAR COMPANY!!!
  }

  postContacts(contact:Contact, companyId: number): Observable<any>{
    //PARAMETRIZAR COMPANY!!!
    let contactDto = new ContactDto(contact);
    contactDto.companyId = companyId;
    return this.httpClient.post<ContactDto>(this.baseURL, contactDto);
  }

  postUploadContacts(formData, companyId: number): Observable<any>{          
    return this.httpClient.post<File>(this.baseURL + '/LoadFromExcel/Company/' + companyId, formData, );////PARAMETRIZAR COMPANY!!!
  }

  
}
