import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as M from "materialize-css/dist/js/materialize";


@Injectable()
export class UIService {
  public showNavBars = new BehaviorSubject<boolean>(true);
  public showGlobalSpinner = new BehaviorSubject<boolean>(false);

  constructor() { }

  showToast(text: string, type: string = '') {
    M.toast({html: text, classes: 'toast-' + type, displayLength: 5*1000});
  }
  
  toggleNavBars(showSearchBar: boolean) {
    this.showNavBars.next(showSearchBar);
  }

  toggleGlobalSpinner(value: boolean) {
    this.showGlobalSpinner.next(value);
  }
}
