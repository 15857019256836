import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuctionLaunchComponent,
    AuctionDetailComponent,
    AuctionRoundComponent,
    AuctionsListComponent,
    SupplierWidgetComponent 
} from './components';
import { AuctionsRoutingModule } from './auctions-routing.module';
import { AuctionsService, AuctionsApiService, IntermediateService } from './services';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/core/core.module';
import { SupplierSearchComponent } from './components/supplier-search/supplier-search.component';
import { ProductSearchComponent } from './components/product-search/product-search.component';
import { AuctionIntermediateComponent } from './components/auction-intermediate/auction-intermediate.component';
import { AuctionItemOfferComponent } from './components/auction-intermediate/auction-item-offer/auction-item-offer.component';
import { IntermediateLandingComponent } from './components/auction-intermediate/intermediate-landing/intermediate-landing.component';
import { IntermediateNotFoundComponent } from './components/auction-intermediate/intermediate-not-found/intermediate-not-found.component';
import { AuctionMessengerComponent } from './components/auction-intermediate/auction-messenger/auction-messenger.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { HelpComponent } from './components/auction-intermediate/help/help.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { FilesModalComponent } from './components/auction-intermediate/files-modal/files-modal.component';

@NgModule({
    declarations: [
        AuctionLaunchComponent,
        AuctionDetailComponent,
        AuctionRoundComponent,
        AuctionsListComponent,
        SupplierWidgetComponent,
        SupplierSearchComponent,
        ProductSearchComponent,
        AuctionIntermediateComponent,
        AuctionItemOfferComponent,
        IntermediateLandingComponent,
        IntermediateNotFoundComponent,
        AuctionMessengerComponent,
        ConfirmationModalComponent,
        HelpComponent,
        ErrorModalComponent,
        FilesModalComponent],
    imports: [
        AuctionsRoutingModule,
        CommonModule,
        CoreModule,
        FontAwesomeModule
    ],
    providers: [AuctionsService, AuctionsApiService, IntermediateService],
    exports: [SupplierWidgetComponent]
})
export class AuctionsModule {}