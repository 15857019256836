import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from '../core/components/error/error.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { DocumentsComponent } from './components/documents/documents.component';
import { MonitorComponent } from './components/monitor/monitor.component';
import { ContactsScreenComponent } from './components/contacts-screen/contacts-screen.component';

const routes: Routes = [
  {
    path: 'contacts',
    component: ContactsScreenComponent
  },
  {
    path: 'documents',
    component: DocumentsComponent
  },
  {
    path: 'monitor',
    component: MonitorComponent
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
