<div class="contacts-grid-container">
    <div class="contacts-grid-filters">
        <!-- Filters go here-->
    </div>

    <table>
        <tr>
            <th *ngFor="let col of gridColumns">{{col.caption}}</th>
        </tr>
        <tr *ngFor="let contact of contacts">
            <td *ngFor="let col of gridColumns">
                {{col.isDynamic && contact.getDynamicValue ? contact.getDynamicValue(col.dynamicContactColumnId) : contact[col.name]}}                                
            </td>
        </tr>
    </table>
</div>