import { Component, OnInit } from '@angular/core';
import { Product, Supplier } from 'src/auctions/model';
import { UIService } from 'src/core/services/ui.service';

@Component({
  selector: 'app-auction-launch',
  templateUrl: './auction-launch.component.html',
  styleUrls: ['./auction-launch.component.css']
})
export class AuctionLaunchComponent implements OnInit {
  addingSupplier = false;
  addingProduct = false;
  suppliers: Supplier[] = [];
  selectedSuppliers: Supplier[] = [];
  product: Product;

  constructor(
    private uiService: UIService
  ) {
  }

  ngOnInit(): void {
    for(let i = 0; i < 5; i++) {
      this.suppliers.push(new Supplier(i));
    }
  }

  cancelSupplierAdd() {
    this.addingSupplier = false;
  }

  selectSupplier(suplier: Supplier) {
    this.selectedSuppliers.push(suplier);
    this.addingSupplier = false;
  }

  removeSupplier(sup) {
    let index = this.selectedSuppliers.indexOf(sup);
    if(index > -1) {
      this.selectedSuppliers.splice(index, 1);
    }
  }
}
