import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/core/services/ui.service';

@Component({
  selector: 'app-intermediate-not-found',
  templateUrl: './intermediate-not-found.component.html',
  styleUrls: ['./intermediate-not-found.component.css']
})
export class IntermediateNotFoundComponent implements OnInit {

  constructor(
    private uiService: UIService
  ) {
    this.uiService.toggleNavBars(false);
  }

  ngOnInit(): void {
  }

}
