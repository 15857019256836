<div class="al-black-back"></div>

<div class="auction-launcher">
    <div class="title">Launch Auction</div>

    <div class="auction-launch-body">
        <!-- Product -->
        <div class="product-container">
            <div class="regular-field">
                <label for="product-to-request">Product To Request</label>
                <input id="product-to-request" placeholder="Search for a product">
            </div>
            <div class="small-field">
                <label for="quantity">Quantity</label>
                <input id="quantity" type="number">
            </div>
            <div class="small-field">
                <label for="contact-attempts">Contact Attempts</label>
                <input id="contact-attempts" type="number">
            </div>
            <div class="regular-field">
                <label for="reply-before-date">Reply before Date</label>
                <input id="reply-before-date" type="date" timezone="en-US">
            </div>
            <div class="regular-field">
                <label for="delivery-date">Delivery Date</label>
                <input id="delivery-date" type="date">
            </div>
        </div>

        <hr class="divider"/>




        <!-- Suppliers -->
        <div class="suppliers-container">
            <div class="suppliers-title-area">
                <div class="suppliers-title">Suppliers</div>

                <div class="app-button app-button-main  add-sup-button"
                    *ngIf="!addingSupplier"  (click)="addingSupplier = true">
                    <i class="material-icons" >add</i>
                </div>
                
                <div class="regular-field" *ngIf="addingSupplier">
                    <core-autocomplete [placeholder]="'Search for a supplier'"
                        (cancel)="cancelSupplierAdd()"
                        (selected)="selectSupplier($event)"
                        [blackList]="selectedSuppliers"
                        [options]="suppliers">
                    </core-autocomplete>
                </div>
            </div>
            
            <div class="suppliers-widgets-container">
                <app-supplier-widget *ngFor="let sup of selectedSuppliers"
                    [supplier]="sup"
                    (remove)="removeSupplier(sup)"></app-supplier-widget>    
            </div>
        </div>

        <!-- launch buttons -->
        <div class="launch-auction-buttons">    
            <div class="app-button app-button-main launch-auction-button">Launch Auction</div>
            <div class="app-button launch-auction-button">Save as Draft</div>
            <div class="app-button launch-auction-button">Cancel</div>
        </div>
    </div>
</div>
