import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { response } from 'express';
import { Observable } from 'rxjs';
import { DynamicContactColumn } from '../model/dynamiccontactcolumn';
import { environment } from 'src/environments/environment';


@Injectable()
export class DynamicContactColumnsApiService {

  private baseURL: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/DynamicContactColumns';
  }


  getDynamicContactColumnsList(companyId: number): Observable<DynamicContactColumn[]> {
    return this.httpClient.get<DynamicContactColumn[]>(this.baseURL + '/Company/' + companyId); //TODO: Parametrize CompanyId
  }


}
