import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UIService } from 'src/core/services/ui.service';
import { environment } from 'src/environments/environment';
import { AuctionBid } from '../model';

@Injectable()
export class IntermediateService {

  auctionCode = null;
  auctionId = null;
  supplierId = null;
  roundNumber = null;

  constructor(private http: HttpClient, private uiService: UIService) {
    this.auctionCode = localStorage.getItem('auctionCode');
    this.roundNumber = localStorage.getItem('roundNumber') ? Number.parseInt(localStorage.getItem('roundNumber')) : null;
    this.supplierId = localStorage.getItem('supplierId') ? Number.parseInt(localStorage.getItem('supplierId')) : null;
    this.auctionId = localStorage.getItem('auctionId') ? Number.parseInt(localStorage.getItem('auctionId')) : null;
  }

  async validateFunction(auctionId, supplierId, roundNumber, auctionCode) {
    return this.http.post<boolean>(`${environment.apiUrl}/api/Intermediate/authorizeround`, {
      auctionId,
      roundNumber,
      authorizationcode: auctionCode,
      auctionSupplierId: supplierId
    }).toPromise().then(res => {
      if (res) {
        this.auctionCode = auctionCode;
        this.roundNumber = roundNumber;
        this.supplierId = supplierId;
        this.auctionId = auctionId;
        localStorage.setItem('auctionCode', auctionCode);
        localStorage.setItem('roundNumber', roundNumber);
        localStorage.setItem('supplierId', supplierId);
        localStorage.setItem('auctionId', auctionId);
      }
      return res;
    });
  }

  async getAuction() {
    this.uiService.toggleGlobalSpinner(true);
    return this.http.post<AuctionBid>(`${environment.apiUrl}/api/Intermediate/view`, {
      auctionId: Number.parseInt(this.auctionId),
      roundNumber: Number.parseInt(this.roundNumber),
      authorizationCode: this.auctionCode,
      auctionSupplierId: Number.parseInt(this.supplierId)
    }).toPromise().then(res => {
      if (res) {
        return res;
      } else {
        return null;
      }
    }).catch(()=>{}).finally(() => {
      this.uiService.toggleGlobalSpinner(false);
    });
  }

  async sendMessage(message) {
    const body = {
      messageId: 0,
      parentMessageId: null,
      sender: 1,
      auctionId: Number.parseInt(this.auctionId),
      auctionSupplierId: Number.parseInt(this.supplierId),
      timestamp: new Date().toISOString(),
      content: message,
      read: false,
      replied: false,
      roundNumber: Number.parseInt(this.roundNumber),
      authorizationCode: this.auctionCode
    };
    this.http.post<any>(`${environment.apiUrl}/api/Messages`, body).toPromise();
  }

  async getMessages() {
    const body = {
      auctionId: Number.parseInt(this.auctionId),
      auctionSupplierId: Number.parseInt(this.supplierId),
      roundNumber: Number.parseInt(this.roundNumber),
      authorizationCode: this.auctionCode
    };
    return this.http.post<any>(`${environment.apiUrl}/api/Intermediate/view-messages`, body).toPromise();
  }

  async updateReadMessage(messageId) {
    const body = {
      messageId: messageId,
      read: true,
      replied: false,
      authorizationCode: this.auctionCode
    }
    return this.http.put<any>(`${environment.apiUrl}/api/Messages/auction-status/intermediate`, body).toPromise();
  }
}
