<div class="al-black-back"></div>

<div class="contact-uploader">
    <div class="title">Upload Contacts</div>
    <div class="contact-create-body">
        <div class="contact-container">     
            <form id="updateFormHtml" fxLayout="row" fxLayoutAlign="center center" #updateForm="ngForm" (submit)="uploadFile()">              
                <div class="upload-label-title">
                    Select a file to upload                
                    <div class="regular-field">                       
                        <input id="file" class="sgnd-btn sgnd-btn-cancel" type="file"
                        (change)="setChosenFile($event)"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>                          
                    </div>
                </div> 
            </form>                   
            <div class="upload-contact-buttons">    
                <button *ngIf="chosenFileName"  form="updateFormHtml" class="sgnd-btn sgnd-btn-ok">Upload</button>
                <button class="sgnd-btn sgnd-btn-cancel" (click)="cancel()" >Cancel</button>
            </div>
        </div>                      
    </div>
</div>
