import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auction } from 'src/auctions/model';

@Injectable()
export class AuctionsService {
  private ApiAuctions: Auction[];
  private filteredAuctions = new BehaviorSubject<Auction[]>([]);

  constructor() { }
}
