import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { Supplier } from 'src/auctions/model';

@Component({
  selector: 'app-supplier-widget',
  templateUrl: './supplier-widget.component.html',
  styleUrls: ['./supplier-widget.component.css']
})
export class SupplierWidgetComponent implements OnInit {
  @Input() public supplier: Supplier;
  @Output() public remove = new EventEmitter();
  public showOptions = false;

  constructor() { }

  ngOnInit(): void {
  }

  onRemove() {
    this.remove.emit();
    this.showOptions = false;
  }
}
