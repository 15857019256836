import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-user-widget',
  templateUrl: './nav-user-widget.component.html',
  styleUrls: ['./nav-user-widget.component.css']
})
export class NavUserWidgetComponent implements OnInit {

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) { }

  ngOnInit(): void {
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  logout() {
    console.log("logout!");
    
    this.auth.logout({ returnTo: this.doc.location.origin });
  }

  

}
