
export class Supplier {
    public supplierId: string;
    public name: string;
    public phone: string;
    public email: string;
    public company: string;

    private readonly testSupNames = ['Adam', 'Alice', 'Albert', 'Andrew', 'Anderson'];

    constructor(index = 0) {
        this.supplierId = index +'';
        this.name = this.testSupNames[index % this.testSupNames.length] + '_' + index;
        this.phone = '786495123' + index;
        this.email = this.name + '@signedeal.com';
        this.company = 'Coca Cola';
    }
 }
