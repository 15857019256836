import { Component, OnInit } from '@angular/core';
import { UIService } from 'src/core/services/ui.service';

@Component({
  selector: 'app-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.css']
})
export class GlobalSpinnerComponent implements OnInit {
  showSpinner: boolean = false;

  constructor(
    private uiService: UIService
  ) {
    this.uiService.showGlobalSpinner.subscribe(value => {
      this.showSpinner = value;
    });
  }

  ngOnInit(): void {
  }

}
