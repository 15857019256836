import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'auction-messenger',
  templateUrl: './auction-messenger.component.html',
  styleUrls: ['./auction-messenger.component.css']
})
export class AuctionMessengerComponent implements OnChanges {

  @ViewChild('messagesRef') messagesRefContent: ElementRef;

  @Input()
  isOpen = false;
  @Input()
  messages = [];
  @Output()
  isOpenChange = new EventEmitter<boolean>();
  @Output()
  onSendMessage = new EventEmitter<string>();
  scrolled = false;
  daysSet: Map<number, string>;

  messageText = '';

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isOpen && changes.isOpen.currentValue) {
      setTimeout(() => this.scrollToBottom(), 1);
      this.daysSet = new Map();
      this.messages.forEach(msg => {
        const date = moment(msg['timestamp']).format('MM/dd');
        if (!Array.from(this.daysSet.values()).includes(date)) {
          this.daysSet.set(msg['timestamp'], date);
        }
      })
    }

  }

  closeMessages() {
    this.isOpen = false;
    this.isOpenChange.emit(false);
  }

  sendMessage() {
    if (this.messageText.length > 0) {
      this.onSendMessage.emit(this.messageText);
      this.messageText = '';
    }
  }

  isFirstInDay(timestamp) {
    return this.daysSet.has(timestamp);
  }
  
  scrollToBottom() {
    try {
      this.messagesRefContent.nativeElement.scrollTop = this.messagesRefContent.nativeElement.scrollHeight;
    } catch(err) {}
  }
}
