<div>
    <div class="input-container">
        <i class="material-icons prefix">search</i>
        <input type="text" class="autocomplete-input"
            [(ngModel)]="inputValue" (ngModelChange)="onChange($event)"
            [placeholder]="placeholder">
        <i class="material-icons cancel" (click)="onCancel()">cancel</i>
    </div>
    <div class="autocomplete-options" *ngIf="inputValue" class="options-container">
        <div *ngFor="let option of optionsToDisplay" (click)="onSelected(option)">
            {{option.name}}
        </div>
    </div>
</div>