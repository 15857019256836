<div class="al-black-back"></div>

<div class="contact-creator">
    <div class="title">New Contact</div>

    <div class="contact-create-body">
        <form [formGroup]="contactCreateForm" (ngSubmit)="onSubmit()">    
            <div class="contact-container">                    
                <div *ngFor="let itemkey of itemKeys" class="regular-field">
                    <label for="itemkey">{{capitalize(itemkey)}}</label>
                    <input *ngIf="itemkey =='email'; else textInput" type="email"  id={{itemkey}} formControlName={{itemkey}} placeholder={{capitalize(itemkey)}}>
                    <ng-template #textInput>
                        <input id={{itemkey}} formControlName={{itemkey}} placeholder={{capitalize(itemkey)}}>
                    </ng-template>                                        
                </div>
                <!-- <div class="regular-field">
                    <label for="name">Name</label>
                    <input id="name" formControlName="name" type="text" placeholder="Name">
                </div>
                <div class="regular-field">
                    <label for="lastName">Last Name</label>
                    <input id="lastName" formControlName="lastname" placeholder="Last Name">
                </div>
                <div class="regular-field">
                    <label for="phone">Phone</label>
                    <input id="phone" formControlName="phone" placeholder="Phone">
                </div>
                <div class="regular-field">
                    <label for="email">Email</label>
                    <input id="email" type="email" formControlName="email" placeholder="Email">
                </div>       -->
                <div class="create-contact-buttons">    
                    <button class="sgnd-btn sgnd-btn-ok" type="submit">Save</button>            
                    <button class="sgnd-btn sgnd-btn-cancel" (click)="cancel()" >Cancel</button>
                </div>
            </div>                      
        </form>       
    </div>
</div>
