import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { AuctionMessage } from 'src/auctions/model';
import { UIService } from 'src/core/services/ui.service';
import { environment } from 'src/environments/environment';
import { IntermediateService } from '../../services/intermediate.service';

@Component({
  selector: 'app-auction-intermediate',
  templateUrl: './auction-intermediate.component.html',
  styleUrls: ['./auction-intermediate.component.css']
})
export class AuctionIntermediateComponent implements OnInit, OnDestroy {
  bid: any = {
    attachments: [],
    currentRound: 0,
    deliveryAddress: '',
    supplierResponseDate: new Date(),
    launchDate: new Date(),
    auctionItems: [],
    remarks: '',
    supplierBidStatus: 0,
    supplierBidDate: new Date(),
    supplierId: '',
    auctionId: 0,
    companyId: '',
    paymentTerm: 0,
    messages: []
  };
  messages: any[];
  messagesInterval = null;
  isShowingMessages = false;
  remainigTime: Date = undefined;
  remainingDays = 0;
  remainingMonths = 0;
  remainingYears = 0;
  unreadMessages = 0;

  downloadingFile = false;

  hasAttachments = false;
  showModal = false;
  isShowingHelp = false;
  errorText = undefined;
  showErrorModal = false;

  showAttachmentsModal = false;
  attachments = [];

  constructor(
    private uiService: UIService,
    private intermediateService: IntermediateService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
  ) {
    this.uiService.toggleNavBars(false);
  }

  showHelpModal() {
    this.isShowingHelp = true;
  }

  async ngOnInit() {
    if (!this.intermediateService.auctionCode || !this.intermediateService.auctionId || !this.intermediateService.roundNumber || !this.intermediateService.supplierId) {
      this.router.navigate(['/auctions/intermediate/not-found']);
      return;
    }
    const auction = await this.intermediateService.getAuction();
    if (auction) {
      let items = [];
      if (auction && auction.auctionItems && Array.isArray(auction.auctionItems)) {
        items = auction.auctionItems.map(item => {
          return {
            ...item,
            originalUnitPrice: undefined,
            originalDeliveryDate: undefined, // new Date(item.originalDeliveryDate),
            proposedDeliveryDate: undefined, // new Date(item.originalDeliveryDate),
            proposedUnitPrice: undefined, // item.originalUnitPrice,
            auctionId: this.intermediateService.auctionId,
            supplierId: this.intermediateService.supplierId
          }
        });
      }
      this.bid = {
        ...auction,
        auctionId: this.intermediateService.auctionId,
        supplierId: this.intermediateService.supplierId,
        auctionItems: items,
        supplierResponseDate: new Date(auction.supplierResponseDate),
        roundNumber: this.intermediateService.roundNumber,
        remarks: auction.remarks || '',
        paymentTerm: auction.paymentTerm || "30"
      };
      if (this.bid.attachments && Object.keys(this.bid.attachments).length > 0) {
        this.hasAttachments = true;
        this.attachments = Object.keys(this.bid.attachments).map(key => {
          // const label = this.bid.attachments[key].split('/')[1]; // Used with paths, so far deprecated
          const label = this.bid.attachments[key];
          return {
            key,
            label
          };
        })
      }
      this.updateRemainingTIme();
      this.intermediateService.getMessages().then(messages => {
        this.messages = messages;
        this.calculateUnreadmessages();
      }).catch(err => console.log(err));
      this.messagesInterval = setInterval(() => {
        this.intermediateService.getMessages().then(messages => {
          this.calculateUnreadmessages();
          this.messages = messages;
          if (this.isShowingMessages) {
            this.markMessagesAsRead();
          }
        }).catch(err => console.log(err));
      }, 30*1000)
    } else {
      this.router.navigate(['/auctions/intermediate/not-found']);
    }
  }

  calculateUnreadmessages() {
    this.unreadMessages = this.messages.filter((message: AuctionMessage) => {
      return message.sender !== 1 && !message.read;
    }).length;
  }

  /**
   * Iterates over every bidded item to verify that they have actual data
   */
  validateAuctionItems() {
    let isValid = true;
    let invalidBid = this.bid.auctionItems.find(ai => {
      let isInvalidItem = !ai.proposedDeliveryDate || !ai.proposedUnitPrice;
      return isInvalidItem;
    });
    if(invalidBid) {
      isValid = false;
      this.showModal = false;
      this.showErrorModal = true;
      this.errorText = "One or more values have not been set for item: " + invalidBid.itemDescription;
    }
    return isValid;
  }

  onSubmit() {
    if(!this.validateAuctionItems()) {
      return;
    }
    let items = [];
    items = this.bid.auctionItems.map(ai => {
      return {
        supplierBidId: 0,
        auctionItemId: ai.auctionItemId,
        supplierId: this.bid.supplierId,
        unitPrice: ai.proposedUnitPrice,
        deliveryDate: ai.proposedDeliveryDate.toISOString()
      };
    })
    const bid = {
      "supplierBid": {
        "supplierBidId": 0,
        "auctionId": this.bid.auctionId,
        "auctionSupplierId": this.bid.supplierId,
        "supplierBidStatus": 0,
        "roundNumber": this.bid.roundNumber,
        "paymentTerm": this.bid.paymentTerm,
        "remarks": this.bid.remarks,
        "supplierBidItems": items,
        "supplierBidDate": new Date().toISOString(),
      },
      "authorizationCode": this.intermediateService.auctionCode
    }
    let apiUrl = environment.apiUrl;
    this.http.post(`${apiUrl}/api/SupplierBid/create`, bid)
    .subscribe(res => {
      this.bid.supplierBidDate = new Date().toISOString();
      this.bid.supplierBidStatus = 2;
      this.closeModal();
    });
  }

  bidWasDone() {
    return this.bid.supplierBidStatus === 2;
  }

  timeExpired() {
    let expired = false;
    try {
      expired = new Date(this.bid.supplierResponseDate).getTime() < new Date().getTime() && this.bid.supplierBidStatus !== 2;
    } catch(err) {
      console.log(err);
    }
    return expired;
  }

  auctionWasCanceled() {
    return this.bid.overallStatus === 11; //AUCTION_CANCELLED = 11
  }

  showMessages() {
    this.isShowingMessages = true;
    this.markMessagesAsRead();
  }

  public updateRemainingTIme() {
    if(this.remainigTime === undefined || this.remainigTime.getTime() > 0) {
      setTimeout(() => {
        let now = Date.now();
        let spRspDate = this.bid.supplierResponseDate.getTime();
        let diff = spRspDate - now;
        this.remainigTime = diff > 0 ? new Date(diff) : new Date(0);
        this.remainingDays = this.remainigTime.getDate();
        this.remainingMonths = this.remainigTime.getMonth();
        this.updateRemainingTIme();
      }, 1000);
    }
  }

  sendMessage(messageText) {
    const messageSent = {
      messageId: 0,
      parentMessageId: null,
      sender: 1,
      auctionId: this.intermediateService.auctionId,
      auctionSupplierId: this.intermediateService.supplierId,
      timestamp: new Date().toISOString(),
      content: messageText,
      read: false,
      replied: false,
      roundNumber: Number.parseInt(this.bid.roundNumber)
    };
    this.messages.push(messageSent);
    this.intermediateService.sendMessage(messageText);
  }

  markMessagesAsRead() {
    this.messages = this.messages.map((message: AuctionMessage) => {
      if (message.sender !== 1 && !message.read) {
        message.read = true;
        this.intermediateService.updateReadMessage(message.messageId);
      }
      return message;
    });
    this.calculateUnreadmessages();
  }

  downloadFile(key) {
    this.downloadingFile = true;
    const promises = [];
    try {
      let attachmentName = this.bid.attachments[key];
      // attachmentName = attachmentName.split('/')[1]; // Used when using paths
      this.http.post(`${environment.apiUrl}/api/Attachments/Auction/${this.bid.auctionId}/Attachment/${key}`, {
        AuthorizationCode: this.intermediateService.auctionCode,
        AuctionSupplierId: this.intermediateService.supplierId,
        RoundNumber: this.intermediateService.roundNumber
      }, {
        responseType: "blob"
      }).toPromise().then(data => {
        return this.downloadFileResponse(data, attachmentName);
      }).catch(()=>{}).finally(() => {
        this.downloadingFile = false;
      })
    } catch(err) {
      this.downloadingFile = false;
    }
  }

  async downloadFileResponse(data, attachmentName) {
    let url = window.URL.createObjectURL(data);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = attachmentName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  showModalAction() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  openAttachmentsModal() {
    this.showAttachmentsModal = true;
  }

  closeAttachmentsModal() {
    this.showAttachmentsModal = false;
  }

  ngOnDestroy() {
    if (this.messagesInterval) {
      clearInterval(this.messagesInterval);
    }
  }
}
