<div class="background-container">
    <div class="at-modal">
        <div class="header">
            <span>Attachments</span>
            <i class="material-icons close-button" (click)="close()">close</i>
        </div>
        <div class="content">
            <div *ngFor="let attachment of attachments" class="attachment" (click)="onDownload(attachment)">
                <span>{{attachment.label}}</span>
                <i class="material-icons" style="color: black;font-size: 1.5rem; padding-top: 5px; margin-right: 10px;">download</i>
            </div>
        </div>
    </div>
</div>