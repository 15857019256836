import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faEdit, faDollarSign, faCalendar, faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

@Component({
  selector: 'app-auction-item-offer',
  templateUrl: './auction-item-offer.component.html',
  styleUrls: ['./auction-item-offer.component.css']
})
export class AuctionItemOfferComponent implements OnInit {

  @Input()
  bestBid: any;
  @Input()
  previousBid: any;
  @Input()
  item: any;
  @Input()
  showProposed: boolean;
  @Input()
  editable: boolean = true;
  @Input()
  currency: string;
  @Output()
  itemChange = new EventEmitter();

  editing = false;

  bestBidPrice: number;
  bestBidDeliveryDate: Date;
  previousBidPrice: number;
  previousBidDeliveryDate: Date;

  faEdit = faChevronRight;
  faMoney = faDollarSign;
  faCalendar = faCalendar;
  faTimes = faTimes;

  constructor() { }

  ngOnInit(): void {
    if (this.bestBid) {
      const bestBidItem = this.bestBid.supplierBidItems.find(bidItem => {
        return this.item.auctionItemId === bidItem.auctionItemId;
      });
      if (bestBidItem) {
        this.bestBidPrice = bestBidItem.unitPrice;
        this.bestBidDeliveryDate = bestBidItem.deliveryDate;
      }
    }
    if (this.previousBid) {
      const previousBidItem = this.previousBid.supplierBidItems.find(bidItem => {
        return this.item.auctionItemId === bidItem.auctionItemId;
      });
      if (previousBidItem) {
        this.previousBidPrice = previousBidItem.unitPrice;
        this.previousBidDeliveryDate = previousBidItem.deliveryDate;
      }
    }
  }

  showEdit() {
    this.editing = this.editable ? !this.editing : false;
  }

  parseDate(strDate) {
    if (strDate) {
      this.item.proposedDeliveryDate = moment(strDate).toDate();
    }
  }

  focusFunction(event) {
    console.log(event)
  }

  

}
