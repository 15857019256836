import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuctionLaunchComponent } from './components';
import { AuctionIntermediateComponent } from './components/auction-intermediate/auction-intermediate.component';
import { IntermediateLandingComponent } from './components/auction-intermediate/intermediate-landing/intermediate-landing.component';
import { IntermediateNotFoundComponent } from './components/auction-intermediate/intermediate-not-found/intermediate-not-found.component';

const root = 'auctions';
const auctionRoutes: Routes = [
	{
		path: root,
		component: AuctionLaunchComponent
	},
	{
		path: root + '/intermediate/:auctionId/:supplierId/:roundNumber',
		component: IntermediateLandingComponent
	},
	{
		path: root + '/intermediate/auction',
		component: AuctionIntermediateComponent
	},
	{
		path: root + '/intermediate',
		component: AuctionIntermediateComponent
	},
	{
		path: root + '/intermediate/not-found',
		component: IntermediateNotFoundComponent
	}

];
@NgModule({
  imports: [RouterModule.forChild(auctionRoutes)],
  exports: [RouterModule],
})
export class AuctionsRoutingModule {}
