import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-files-modal',
  templateUrl: './files-modal.component.html',
  styleUrls: ['./files-modal.component.css']
})
export class FilesModalComponent implements OnInit {

  @Input()
  attachments: [];

  @Output()
  closeModal = new  EventEmitter<any>();
  @Output()
  downloadFile = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    console.log(this.attachments)
    this.closeModal.emit(true);
  }

  onDownload(attachment) {
    this.downloadFile.emit(attachment.key);
  }
}
