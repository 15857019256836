<app-list-bulk-edit></app-list-bulk-edit>
<div class="filters-list-container">
    <div class="filters-container">
        
        <!-- Might use a separated component here -->
        <div class="filter-cards-container">
            <div class="filter-card">
                <div class="filter-card-title">
                    <i class="material-icons">contact_mail</i>
                    <span>Manage Contacts</span>
                </div>
                <div>
                    <div class="filter-card-btn" (click)="onNew()">New Contact</div>
                    <div class="filter-card-btn" (click)="onUpload()">Upload Contacts</div>
                    <div class="filter-card-btn" (click)="onDeleteSelected()">Delete Selected Contacts</div>
                </div>
            </div>

            <div class="filter-card" style="margin-top: 50px">
                <div class="filter-card-title">
                    <i class="material-icons">filter_list</i>
                    <span>Filter Contacts</span>
                </div>
                <div>
                    <div class="filter-card-btn" (click)="showAllContacts()">
                        TEST Sucess Modal
                        <!-- All Contacts -->
                    </div>
                    <div class="filter-card-btn" (click)="showMyContacts()">
                        TEST Error Modal
                        <!-- My Contacts -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="list-container">
        <app-contacts-grid></app-contacts-grid>
    </div>
</div>
<div *ngIf="showAddContactModal.value">
    <app-add-contact></app-add-contact>
</div>
<div *ngIf="showContactAddSuccess.value">
    <app-add-contact-sucess></app-add-contact-sucess>
</div>
<div *ngIf="showContactAddError.value">
    <app-add-contact-error></app-add-contact-error>
</div>
<div *ngIf="showUpladContactFile.value">
    <app-upload-contacts-file></app-upload-contacts-file>
</div>
<div *ngIf="showContactsUploadSuccess.value">
    <app-upload-contacts-sucess></app-upload-contacts-sucess>
</div>
<div *ngIf="showContactsUploadError.value">
    <app-upload-contacts-error></app-upload-contacts-error>
</div>
