import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ContactsService } from 'src/app/services';

@Component({
  selector: 'app-upload-contacts-file',
  templateUrl: './upload-contacts-file.component.html',
  styleUrls: ['./upload-contacts-file.component.css']
})
export class UploadContactsFileComponent implements OnInit {
  // uploadContactForm;
  fileName: string;
  file;


  chosenFileName: string;
  chosenFile: any;


  constructor(
    public _contactService: ContactsService,
  ) {    
  }


  setChosenFile(fileInput: Event) {
    console.log(fileInput);
    const control: any = fileInput.target;
    if (!control.files || control.length === 0) {
      this.chosenFileName = null;
      this.chosenFile = null;
    } else {
      this.chosenFileName = control.files[0].name;
      this.chosenFile = control.files[0];
    }
  }

  uploadFile() {
    const formData = new FormData();
    formData.append('file', this.chosenFile, this.chosenFileName);
    this._contactService.UploadExcel(formData); 
  }


  ngOnInit(): void {
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
  }
  
  cancel(){
    this._contactService.showUpladContactFile.next(false);
  } 

  ngDestroy(){
    this.file = null;
  }

}
