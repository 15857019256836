<div class="bulk-container">
    <div class="bulk-buttons">
        <div [hidden]="hideButtons">
            <div class="bulk-button"><i class="material-icons">add_circle</i></div>
            <div class="bulk-button"><i class="material-icons">edit</i></div>
            <div class="bulk-button"><i class="material-icons">delete</i></div>
        </div>
    </div>
    <app-paginator></app-paginator>
</div>
