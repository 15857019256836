import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { SideNavContentComponent } from './components/side-nav-content/side-nav-content.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NavUserWidgetComponent } from './components/nav-user-widget/nav-user-widget.component';
import { UIService } from '../core/services/ui.service';
import { ErrorComponent } from '../core/components/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import json from 'highlight.js/lib/languages/json';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { environment as env } from '../environments/environment';
import { GlobalSpinnerComponent } from './components/global-spinner/global-spinner.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ListBulkEditComponent } from './components/list-bulk-edit/list-bulk-edit.component';
import { UserService } from 'src/core/services/user.service';

@NgModule({
    declarations: [
        AutocompleteComponent,
        SideNavContentComponent,
        SpinnerComponent,
        NavUserWidgetComponent,
        ErrorComponent,
        NavBarComponent,
        LoadingComponent,
        GlobalSpinnerComponent,
        PaginatorComponent,
        ListBulkEditComponent
    ],
    imports: [
        FormsModule, 
        BrowserModule,
        HttpClientModule,
        NgbModule,
        FontAwesomeModule,
        AuthModule.forRoot({
          ...env.auth,
          httpInterceptor: {
            ...env.httpInterceptor,
          },
        }),
    ],
    providers: [
        UIService,
        UserService,/*
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true,
        },*/
        {
        provide: Window,
        useValue: window,
        }
    ],
    exports: [
        AutocompleteComponent,
        FormsModule,
        BrowserModule,
        SideNavContentComponent,
        SpinnerComponent,
        NavUserWidgetComponent,
        ErrorComponent,
        NavBarComponent,
        LoadingComponent,
        GlobalSpinnerComponent,
        PaginatorComponent,
        ListBulkEditComponent
    ]
})
export class CoreModule {}
