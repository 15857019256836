<div class="interm-container intermediate-size">
    <div *ngIf="downloadingFile" class="blocker">
        <i class="material-icons rotating" style="color: white;font-size: 2.5rem; margin-right: 10px;" *ngIf="downloadingFile">data_usage</i>
    </div>
    <app-files-modal
        *ngIf="hasAttachments && showAttachmentsModal"
        [attachments]="attachments"
        (closeModal)="closeAttachmentsModal()"
        (downloadFile)="downloadFile($event)"
        >
    </app-files-modal>
    <div class="component-container">
        <auction-messenger [(isOpen)]="isShowingMessages" [messages]="messages" (onSendMessage)="sendMessage($event)"></auction-messenger>
        <help-modal [(isOpen)]="isShowingHelp" [userEmail]="bid.userEmail"></help-modal>
        
        <div class="interm-header">
            <div class="message-icon" *ngIf="hasAttachments">
                <i class="material-icons" style="color: white;font-size: 2.5rem; padding-top: 5px; margin-right: 10px;"
                    (click)="openAttachmentsModal()" *ngIf="!downloadingFile">download</i>
            </div>
            <div class="message-icon">
                <i class="material-icons" style="color: white;font-size: 2.5rem; padding-top: 5px; margin-right: 10px;"
                    (click)="showHelpModal()">contact_support</i>
            </div>
            <div class="message-icon">
                <i class="material-icons" style="color: white;font-size: 2.5rem; padding-top: 5px;"
                    (click)="showMessages()">chat</i>
                <div class="unread-container" *ngIf="unreadMessages > 0">{{unreadMessages}}</div>
            </div>
        </div>
        <div class="interm-body">
            <div class="body-title">P2P - Auction Process Status</div>
            <div class="body-sub-title">
                This is round {{bid.currentRound}} of {{bid.numberOfRounds}} from auction {{bid.auctionId}} launched by {{bid.userName}} for the below items
            </div>
            <div class="bid-form">
                <div class="padded-content main-date">
                    <div>Auction Launch Date</div>
                    <div>{{bid.launchDate | date:'MM-dd-YYYY'}}</div>
                </div>
                <div class="padded-content main-date">
                    <div>Remaining Time</div>
                    <div *ngIf="remainigTime">{{remainingYears ? remainingYears + ' year(s) ' : ''}}{{remainingMonths ? remainingMonths + ' month(s) ' : ''}}{{remainingDays ? remainingDays + ' day(s) ' : ''}}{{remainigTime | date:'hh:mm:ss'}}</div>
                </div>
                <div class="padded-content main-date" *ngIf="bid && bid.messageToSupplier">
                    <div>Message</div>
                    <div>{{bid.messageToSupplier}}</div>
                </div>
                
                <div class="main-date" style="border-bottom: none;">
                    <div>Your Response Details</div>
                    <div class="padded-content">
                        <div *ngFor="let item of bid.auctionItems">
                            <app-auction-item-offer
                                [item]="item"
                                [currency]="bid.currency"
                                [bestBid]="bid.previousRoundWinningSupplierBid"
                                [previousBid]="bid.previousRoundCurrentSupplierBid"
                                [showProposed]="false"
                                [editable]="!bidWasDone() && !timeExpired() && !auctionWasCanceled()">
                            </app-auction-item-offer>
                        </div>
                    </div>
                    <div class="padded-content remarks-container">
                        <label>Remarks</label>
                        <textarea placeholder="Remarks..." [(ngModel)]="bid.remarks" [disabled]="bidWasDone() || timeExpired() || auctionWasCanceled()"
                        style="border-radius: 2px; height: 6em; resize: none; padding: 10px; border: 2px solid rgb(4, 20, 51);"></textarea>
                    </div>
                    
                    <div class="padded-content payment-term-container">
                        <label>Payment Term</label>
                        <select class="browser-default custom-select" [(ngModel)]="bid.paymentTerm" [disabled]="bidWasDone() || timeExpired() || auctionWasCanceled()">
                            <option value="30">30 Days</option>
                            <option value="45">45 Days</option>
                            <option value="60">60 Days</option>
                        </select>
                    </div>
        
                    <div class="padded-content already-responded" *ngIf="bidWasDone()">
                        You have replied to this proposal on {{bid.supplierBidDate | date:'MM-dd-YYYY'}}
                    </div>
                    <div class="padded-content already-responded" *ngIf="timeExpired()">
                        The reply date has expired
                    </div>
                    <div class="padded-content already-responded" *ngIf="auctionWasCanceled()">
                        The auction was canceled
                    </div>
                    <div *ngIf="!bidWasDone() && !timeExpired() && !auctionWasCanceled()" class="submit-btn" (click)="showModalAction()" >Submit</div>
                </div>
    
            </div>
        </div>
    </div>
    <app-confirmation-modal [showModal]="showModal" [mainText]="'Are you sure you want to submit? This action cannot be reverted'" (confirm)="onSubmit()" (close)="closeModal()"></app-confirmation-modal>
    <app-error-modal [showModal]="showErrorModal" [mainText]="errorText" (accept)="showErrorModal = false"></app-error-modal>
</div>
