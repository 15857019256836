import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'core-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit {
  public inputValue: string;
  public optionsToDisplay: any;

  @Input()
  public placeholder: string;
  @Input()
  public options: any = [];
  @Input()
  public blackList: any = [];
  @Input()
  public maxSuggestions: number = 4;
  @Output()
  public cancel = new EventEmitter();
  @Output()
  public selected = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onCancel()
  {
    this.cancel.emit();
  }

  onChange(value: any) {
    this.updateOptions();
  }

  updateOptions() {
    // filter by txt and blklst
    let tempArr = this.options.filter(x => {
      let hasTxt = false;
      let blacklistedElem = this.blackList.find(y => y.supplierId === x.supplierId);
      if(!blacklistedElem && this.inputValue) {
        Object.keys(x).forEach(key => {
          hasTxt = hasTxt || (x[key] && x[key].toLowerCase && x[key].toLowerCase().includes(this.inputValue.toLowerCase()));
        });
      }
      return hasTxt || !this.inputValue;
    });
    
    // sort (not working)
    tempArr.sort((a,b)=>{ return a.name < b.name; });
    
    // limit
    this.optionsToDisplay = tempArr.splice(0,this.maxSuggestions);
  }

  onSelected(option: any) {
    this.inputValue = undefined;
    this.selected.emit(option);
  }
}
