<div class="messenger-modal-container" *ngIf="isOpen">
    <div class="messenger-modal">
        <i class="material-icons close-button" (click)="closeMessages()">close</i>
        <div class="messenger-modal-header">
            Messages
        </div>
        <div class="chat-content-container" #messagesRef>
            <div *ngFor="let message of messages" class="message-item-container" [class.left]="message.sender === 0" [class.right]="message.sender === 1">
                <div class="day-separator" *ngIf="isFirstInDay(message.timestamp)">
                    <span class="day-container">{{message.timestamp | date: 'MM/dd'}}</span>
                </div>
                <div class="message-item">
                    {{message.content}}
                    <div class="stamp">
                        {{message.timestamp | date: 'HH:mm'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="chat-input-container">
            <div class="chat-input">
                <input [(ngModel)]="messageText" (keydown.enter)="sendMessage()"/>
                <div class="send-button-container" (click)="sendMessage()"><i class="material-icons send-icon">send</i></div>
            </div>
        </div>
    </div>
</div>