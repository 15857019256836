import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-bulk-edit',
  templateUrl: './list-bulk-edit.component.html',
  styleUrls: ['./list-bulk-edit.component.css']
})
export class ListBulkEditComponent implements OnInit {
  @Input() public hideButtons = true;

  constructor() { }

  ngOnInit(): void {
  }

}
