import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { CoreModule } from 'src/core/core.module';
import { AuctionsModule } from 'src/auctions/auctions.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { TabGroupComponent } from './components/tab-group/tab-group.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NegotiationCounterComponent } from './components/negotiation-counter/negotiation-counter.component';
import { ContactsApiService, DynamicContactColumnsApiService, ContactsService, DynamicValuesApiService } from 'src/app/services';
import { DocumentsComponent } from './components/documents/documents.component';
import { MonitorComponent } from './components/monitor/monitor.component';
import { ContactsScreenComponent } from './components/contacts-screen/contacts-screen.component';
import { ContactsGridComponent } from './components/contacts-grid/contacts-grid.component';
import { AddContactComponent } from './components/add-contact/add-contact.component';
import { AddContactSucessComponent } from './components/add-contact-sucess/add-contact-sucess.component';
import { AddContactErrorComponent } from './components/add-contact-error/add-contact-error.component';
import { UploadContactsFileComponent } from './components/upload-contacts-file/upload-contacts-file.component';
import { UploadContactsSucessComponent } from './components/upload-contacts-sucess/upload-contacts-sucess.component';
import { UploadContactsErrorComponent } from './components/upload-contacts-error/upload-contacts-error.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TabGroupComponent,
    NegotiationCounterComponent,
    DocumentsComponent,
    MonitorComponent,
    ContactsScreenComponent,
    ContactsGridComponent,
    AddContactComponent,
    AddContactSucessComponent,
    AddContactErrorComponent,
    UploadContactsFileComponent,
    UploadContactsSucessComponent,
    UploadContactsErrorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    AuctionsModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [ContactsApiService, DynamicContactColumnsApiService, ContactsService, DynamicValuesApiService],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
