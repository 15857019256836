import { Injectable, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';

@Injectable()
export class UserService implements OnInit {
  public currentUser: any = {companyId: UserService.DEFAULT_COMPANY };
  private static readonly DEFAULT_COMPANY = 1;

  constructor(
    private auth: AuthService
  ) {
    this.auth.user$.subscribe(currentUser => {
      this.currentUser = currentUser ? currentUser : this.currentUser;
      this.currentUser.companyId = UserService.DEFAULT_COMPANY;
    });
  }

  ngOnInit(): void { }

  currentUserAsync(): Observable<any> {
    let observable = new Observable<any>(observer => {
      // TODO: Company should be obtained from user claims or at
      // least call the api to get this data from a given token.
      observer.next({companyId: UserService.DEFAULT_COMPANY });
    });
    return observable;
  }
}
