import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  @Input()
  showModal = false;

  @Input()
  mainText = '';

  @Output()
  accept = new EventEmitter();

  onAccept() {
    this.accept.emit();
  }
}
