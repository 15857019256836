<div *ngIf="supplier" class="sup-widget-container">
    <div class="sup-row">
        <div class="sup-name"> {{supplier.name}} </div>
        <div class="sup-company"> {{supplier.company}} </div>
        <div class="sup-options" (click)="showOptions=true">
            <i class="material-icons">more_vert</i>
        </div>
        <div *ngIf="showOptions" class="sup-options-menu">
            <div class="sup-options-menu-btn" (click)="onRemove()">Remove</div>
            <div class="sup-options-menu-btn" (click)="showOptions = false">Change Language</div>
            <div class="sup-options-menu-btn" (click)="showOptions = false">Contact Options</div>
        </div>
    </div>
    <div class="sup-email"> {{supplier.email | lowercase}} </div>
    <div class="sup-phone"> {{supplier.phone}} </div>
</div>
