import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auction } from 'src/auctions/model';

@Injectable()
export class AuctionsApiService {

  constructor() { }

  launchAuction(auction: Auction): Observable<boolean> {
    return this.returnEmptyObs();
  }

  saveAuction(auction: Auction): Observable<boolean> {
    return this.returnEmptyObs();
  }

  deleteAuction(auctionId: string): Observable<boolean> {
    return this.returnEmptyObs();
  }

  getAuction(auctionId: string): Observable<Auction> {
    return this.returnEmptyObs();
  }

  getAuctionsList(): Observable<Auction[]> {
    return this.returnEmptyObs();
  }

  // TODO: Delete me...
  private returnEmptyObs(): Observable<any>  {
    let obs = new Observable<any>(observer => {
      observer.next([
        // TODO: Add Sample auctions here...
      ]);
    });
    return obs;
  }

}
