import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/model/contact';
import { ContactsService } from 'src/app/services';
import { FormBuilder } from '@angular/forms';
import { DynamicContactColumn } from 'src/app/model/dynamiccontactcolumn';
import { not } from '@angular/compiler/src/output/output_ast';
import { DynamicValue } from 'src/app/model/dynamicvalue';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {
  itemKeys= ["name", "lastName", "phone", "email"];
  items={};
  contactCreateForm;
  dynamicContactColumns;
  newContact: Contact;
  exceptProps= ["contactId", "dynamicColumns"];
  dynamicKeys=[];

  constructor(
    public _contactService: ContactsService,
    private formBuilder: FormBuilder,
  ) {
    this._contactService.dynamicContactColumns.subscribe(res => {
      this.dynamicContactColumns = res.map(x =>(new DynamicContactColumn(x)));
      this.buildItems(this.dynamicContactColumns);
      this.buildcontactCreateForm(this.items); 
    });
  }

  buildcontactCreateForm(items) {
    this.contactCreateForm = this.formBuilder.group(items);   
  }

  buildItems(dynColumns:DynamicContactColumn[]) {
    //extracts  properties from Contact object except dynamicValues and Id
    let aux =  new Contact("");
    for (const property in aux) {                    
      if (this.exceptProps.indexOf(property) == -1 && property != "dynamicValues" && property != "companyId") {
        this.items[property]="";
        this.addItemKey(property);
      };
    }
    dynColumns.forEach(col => {
      this.items[col.name]="";
      this.addItemKey(col.name);
    this.dynamicKeys.push(col.name);
  });                   
  }

  addItemKey(str) {
    if (this.itemKeys.indexOf(str) == -1) this.itemKeys.push(str);
  }

  onSubmit(): void{      
    this.saveContact(this.contactCreateForm);
  }


  saveContact(form){
    let newContact = this.mapToContact(form);  
    let newDynamicValues = this.mapToDynamicValues(form);  
    this._contactService.saveContact(newContact, newDynamicValues);
    this._contactService.showAddContactModal.next(false);
  }

  mapToDynamicValues(form: any): Array<DynamicValue> {
    let result =new Array<DynamicValue>();
    this.dynamicContactColumns.forEach((dynCol:DynamicContactColumn) => {
      let dynVal = new DynamicValue({
          dynamicContactColumnId: dynCol.dynamicContactColumnId,     
          value: form.value[dynCol.name]
      })
      result.push(dynVal);    
    });
    return result;
  }


  mapToContact(form):Contact {
    let newContact = new Contact(form.value);    
    return newContact;    
  }

  ngOnInit(): void {
  }

  cancel(){
    this._contactService.showAddContactModal.next(false);
  } 

  public capitalize(str: string) {
    let result = '';
    let words = str.split(/(?=[A-Z])/);
    words.forEach(w => {
      result += w.charAt(0).toUpperCase() + w.substring(1) + (w.length > 1 ? ' ' : '');
    });
    return result.trim();
  }

}
