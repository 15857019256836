import { DynamicValue } from "./dynamicvalue";


export class Contact {
    public contactId: string;
    public email: string;
    public name: string;
    public lastName: string;
    public phone: string;
    public dynamicValues: Array<DynamicValue>;
    public companyId: number;
    
    constructor(contact: any) {
        this.contactId = contact.contactId;
        this.email = contact.email;
        this.name = contact.name;
        this.lastName = contact.lastName;
        this.phone = contact.phone;       
        this.dynamicValues = contact.dynamicValues ? contact.dynamicValues : [];
    }

    public getDynamicValue(dynamicContactColumnId:any) {
        let result = '';
         this.dynamicValues ? 
            this.dynamicValues.forEach(x=> {
                if(x.dynamicContactColumnId == dynamicContactColumnId) result = x.value}) 
            : result;
        return result;
    }
}

export class SaveContactDto{
    contact:Contact;    
    dynamicValules: Array<DynamicValue>;
}

export class ContactDto{
    public contactId: string;
    public email: string;
    public name: string;
    public lastName: string;
    public phone: string;
    public companyId: number;
    public dynamicValues: Array<DynamicValue>;

    constructor(contact: Contact) {
        this.contactId = contact.contactId;
        this.email = contact.email;
        this.name = contact.name;
        this.lastName = contact.lastName;
        this.phone = contact.phone;
        this.companyId = contact.companyId;
        this.dynamicValues = contact.dynamicValues ? contact.dynamicValues : [];
    }
}
