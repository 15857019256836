import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { ContactsService } from 'src/app/services';


@Component({
  selector: 'app-contacts-screen',
  templateUrl: './contacts-screen.component.html',
  styleUrls: ['./contacts-screen.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class ContactsScreenComponent implements OnInit {


  showAddContactModal = new BehaviorSubject<boolean>(false);
  showContactAddSuccess = new BehaviorSubject<boolean>(false);
  showContactAddError = new BehaviorSubject<boolean>(false);
  showUpladContactFile = new BehaviorSubject<boolean>(false);
  showContactsUploadSuccess = new BehaviorSubject<boolean>(false);
  showContactsUploadError = new BehaviorSubject<boolean>(false);

  constructor(public _contactService: ContactsService)
  {}
     
  
  ngOnInit(): void {    
    this._contactService.showAddContactModal.subscribe(showModal =>{
      this.showAddContactModal.next(showModal);
    });
    this._contactService.showContactAddSuccess.subscribe(showModal =>{
      this.showContactAddSuccess.next(showModal);
    });
    this._contactService.showContactAddError.subscribe(showModal =>{
      this.showContactAddError.next(showModal);
    });
    this._contactService.showUpladContactFile.subscribe(showModal =>{
      this.showUpladContactFile.next(showModal);
    });
    this._contactService.showContactsUploadSuccess.subscribe(showModal =>{
      this.showContactsUploadSuccess.next(showModal);
    });
    this._contactService.showContactsUploadError.subscribe(showModal =>{
      this.showContactsUploadError.next(showModal);
    });
  }

  onNew() {
    this._contactService.showAddContactModal.next(true);
  }

  onUpload() {
    this._contactService.showUpladContactFile.next(true);    
  }

   onDeleteSelected() {
    // TODO: Implement me...
  }

  async showAllContacts() {
    //TEST ERROR MODAL DELETE ME
    this._contactService.showContactAddSuccess.next(true);
    await this.sleep(1000);
    this._contactService.showContactAddSuccess.next(false);

    this._contactService.showContactsUploadSuccess.next(true);
    await this.sleep(1000);
    this._contactService.showContactsUploadSuccess.next(false);
  }

  async showMyContacts() {
    //TEST ERROR MODAL DELETE ME
    this._contactService.showContactAddError.next(true);
    await this.sleep(1000);
    this._contactService.showContactAddError.next(false);

    this._contactService.showContactsUploadError.next(true);
    await this.sleep(1000);
    this._contactService.showContactsUploadError.next(false);    
  }
  
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
