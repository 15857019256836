import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-negotiation-counter',
  templateUrl: './negotiation-counter.component.html',
  styleUrls: ['./negotiation-counter.component.css']
})
export class NegotiationCounterComponent implements OnInit {
  public counter = 2400;
  
  constructor() { }

  ngOnInit(): void {
  }

}
