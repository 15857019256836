
<!--
<div class="container" *ngIf="auth.isLoading$ | async">
    <app-loading></app-loading>
</div>
-->

<div #loaded class="sd-tab-nav">
    <div>
        <a *ngFor="let menu of menus"
            [ngClass]="isSelected(menu) ? 'sd-menu menu-selected' : 'sd-menu'" [routerLink]="'/' + menu.toLowerCase() ">{{menu}}</a>
    </div>
    <div class="sd-extras">
        <app-negotiation-counter></app-negotiation-counter>
    </div>
</div>
