<div class="interm-container intermediate-size">
    <div class="landing-container">
        <div class="interm-header"></div>
        <div class="landing-content">
            <div>
                Please enter the validation code that was sent to your registered email
            </div>
            <div class="input-field col s6" style="flex: 0">
                <input id="validation_code" type="text" class="validate" [(ngModel)]="code">
                <label for="validation_code">Code</label>
            </div>
            <a class="waves-effect waves-light btn-small blue" (click)="validateCode()">Confirm</a>
        </div>
    </div>
</div>
