<div *ngIf="showModal" class="confirmation-modal">
    <div class="modal-container">
        <div class="head">
            Error
        </div>
        <div class="modal-body">
            <div class="main-text">
                {{mainText}}
            </div>
        </div>
        <div class="buttons-container">
            <a class="waves-effect waves-light btn red" (click)="onAccept()">Accept</a>
        </div>
    </div>
</div>