import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/model/contact';
import { DynamicContactColumn } from 'src/app/model/dynamiccontactcolumn';
import { ContactsService } from 'src/app/services';

@Component({
  selector: 'app-contacts-grid',
  templateUrl: './contacts-grid.component.html',
  styleUrls: ['./contacts-grid.component.css']
})
export class ContactsGridComponent implements OnInit {
  public contacts: Array<Contact> = [];
  public dynamicContactColumns: Array<DynamicContactColumn> = [];
  public gridColumns: Array<IGridColumn> = [];

  constructor(
    public contactsService: ContactsService
  ) {
    contactsService.getData();
  }

  ngOnInit(): void {
    this.contactsService.dynamicContactColumns.subscribe(values =>{
      this.dynamicContactColumns = [];
      this.dynamicContactColumns = values;
    });
    this.contactsService.contacts.subscribe(values => {
      this.contacts = [];
      this.contacts = values;
      this.extractColumns();
    }); 
  }

  extractColumns() {
    if(this.contacts && this.contacts.length) {
      let columns = Object.keys(this.contacts[0]);
      this.gridColumns = [];
      // Convert Column names from camel case to capitalized spaced names
      columns.forEach(col => {
        if(!col.includes('dynamic')) {
          let colName = this.capitalize(col);
          this.gridColumns.push({name: col, caption: colName, isDynamic: false, dynamicContactColumnId :0 });
        }
      });
      this.loadDynamicColumns();     
    }
  }

  loadDynamicColumns() {    
    this.dynamicContactColumns.forEach(col => {
      let colName = this.capitalize(col.name);
      this.gridColumns.push({name: col.name, caption: colName, isDynamic: true, dynamicContactColumnId: col.dynamicContactColumnId});
    });  
  }

  private capitalize(str: string) {
    let result = '';
    let words = str.split(/(?=[A-Z])/);
    words.forEach(w => {
      result += w.charAt(0).toUpperCase() + w.substring(1) + (w.length > 1 ? ' ' : '');
    });
    return result.trim();
  }

  ngOnDestroy(): void {
    this.contactsService.dynamicContactColumns.unsubscribe;
    this.contactsService.contacts.unsubscribe;
  }
}

export interface IGridColumn {
  name: string;
  caption: string;  
  isDynamic: boolean;
  dynamicContactColumnId:any;
}