import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ContactsApiService } from '../services/contacts-api.service';
import { DynamicContactColumnsApiService } from '../services/dynamiccontactcolumns-api.service';
import { Contact } from '../model/contact';
import { DynamicContactColumn } from '../model/dynamiccontactcolumn';
import { DynamicValuesApiService } from './dynamicvalues-api.service';
import { DynamicValue } from '../model/dynamicvalue';
import { UserService } from 'src/core/services/user.service';

@Injectable()
export class ContactsService implements OnInit {
  public contacts = new BehaviorSubject<Contact[]>([]);
  public filteredContacts = new BehaviorSubject<Contact[]>([]);
  public dynamicContactColumns = new BehaviorSubject<DynamicContactColumn[]>([]);

  // TODO: Mover esto a otro lado, no tiene sentido que esten aca.
  public showAddContactModal = new BehaviorSubject<boolean>(false);
  public showContactAddSuccess = new BehaviorSubject<boolean>(false);
  public showContactAddError = new BehaviorSubject<boolean>(false);
  public showUpladContactFile = new BehaviorSubject<boolean>(false);
  public showContactsUploadSuccess = new BehaviorSubject<boolean>(false);
  public showContactsUploadError = new BehaviorSubject<boolean>(false);

  constructor(
    private userService: UserService,
    private contactApiService: ContactsApiService,
    private dynamicColumnsApiService: DynamicContactColumnsApiService,
    private dynamicValuesApiSerrvice: DynamicValuesApiService
  ) {
    this.userService.currentUserAsync().subscribe(currentUser => {
      this.getData();
    });
  }

  getData() {
    this.contacts.next([]);
    this.dynamicContactColumns.next([]);
    forkJoin([
      this.dynamicColumnsApiService.getDynamicContactColumnsList(this.userService.currentUser.companyId),
      this.contactApiService.getContactsList(this.userService.currentUser.companyId)]
    ).subscribe((res: Array<any>) => {
      this.dynamicContactColumns.next(res[0].map(x => (new DynamicContactColumn(x))));
      this.contacts.next(res[1].map(x => (new Contact(x))));
    });
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public saveContact(contact: Contact, dynamicValues: DynamicValue[]): void {
    this.contactApiService.postContacts(contact, this.userService.currentUser.companyId).subscribe(
      async resContactId => {
        if (resContactId > 0) {
          this.saveDynamicValues(dynamicValues, resContactId)
        }
        else {
          this.showContactAddError.next(true);
          await this.sleep(1500);
          this.showContactAddError.next(false);
        }
      }
    );
  }

  async saveDynamicValues(dynamicValues: Array<DynamicValue>, contactId) {
    dynamicValues.forEach(dynVal => {
      dynVal.contactId = contactId;
    });
    await this.dynamicValuesApiSerrvice.postDynamicValueList(dynamicValues, contactId).subscribe(async result => {
      console.log("POST postDynamicValueList result", result);
      this.getData();
      if (result > 0) {
        this.showContactAddSuccess.next(true);
        await this.sleep(1500);
        this.showContactAddSuccess.next(false);
      }
      else {
        this.showContactAddError.next(true);
        await this.sleep(1500);
        this.showContactAddError.next(false);
      }
      return true;
    })
  }

  UploadExcel(formData): void {
    this.contactApiService.postUploadContacts(formData, this.userService.currentUser.companyId)
    .subscribe(
      async res => {
        this.showUpladContactFile.next(false);
        if (res > 0) {
          this.showContactsUploadSuccess.next(true);
          await this.sleep(1500);
          this.showContactsUploadSuccess.next(false);
        }
        else {
          this.showContactsUploadError.next(true);
          await this.sleep(1500);
          this.showContactsUploadError.next(false);
        }
        this.getData();
      }
    )
  }


  ngOnInit(): void {
  }

}
