import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntermediateService } from 'src/auctions/services';
import { UIService } from 'src/core/services/ui.service';
import * as M from "materialize-css/dist/js/materialize";

@Component({
  selector: 'intermediate-landing',
  templateUrl: './intermediate-landing.component.html',
  styleUrls: ['./intermediate-landing.component.css']
})
export class IntermediateLandingComponent implements OnInit {

  private auctionId = null;
  private supplierId = null;
  private roundNumber = null;
  code = '';

  constructor(
    private uiService: UIService,
    private route: ActivatedRoute,
    private router: Router,
    private intermediateService: IntermediateService
  ) {
    this.uiService.toggleNavBars(false);
  }

  async validateCode() {
    const isValid = await this.intermediateService.validateFunction(this.auctionId, this.supplierId, this.roundNumber, this.code);
    if (!isValid) {
      M.toast({html: 'Invalid Code!'});
    } else {
      this.router.navigate(['/auctions/intermediate/auction']);
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const { auctionId, roundNumber, supplierId } = params;
      this.auctionId = auctionId;
      this.roundNumber = roundNumber;
      this.supplierId = supplierId;
    });
  }

}
